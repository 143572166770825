export default function block_events_filter() {

	function bind_events_filter() {

		$( '.fpc-events-filter-content nav>ul>li>a' ).on( 'click', function ( e ) {
			e.preventDefault();
			const self = $( this );
			$( '.fpc-events-filter-content nav>ul>li>a' ).removeClass( 'active' );
			self.addClass( 'active' );
			const grid = $( '.fpc-events-grid' );
			grid.html( '' );

			const btn = $( '.fpc-events-grid-more>.btn' );
			btn.attr( 'data-next-page', 1 );
			btn.attr( 'data-attractions', self.attr( 'data-attractions' ) );
			btn.attr( 'data-classifications', self.attr( 'data-classifications' ) );
			btn.attr( 'data-venues', self.attr( 'data-venues' ) );
			btn.attr( 'data-mode', self.attr( 'data-mode' ) );
			btn.attr( 'data-posts-per-page', self.attr( 'data-posts-per-page' ) );
			btn.trigger( 'click' );
		} );
	}

	if ( window.acf ) {
		window.acf.addAction( 'render_block_preview/type=fpc-events-filter', function ( block ) {
			bind_events_filter();
		} );
	} else {
		bind_events_filter();
	}
}