import block_merch_carousel from './blocks/merch';
import block_partners_carousel from './blocks/partners';
import block_events from './blocks/events';
import block_events_carousel from './blocks/events-carousel';
import block_faqs from './blocks/faqs';
import block_photo_galleries from './blocks/photo-galleries';
import block_ads from './blocks/ads';
import block_events_filter from './blocks/events-filter';

require( 'readmore-js' );

jQuery( document ).ready( function ( $ ) {
	// Menu toggle
	$( '#header .hamburger' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).toggleClass( 'is-active' );
		$( '.header-bottom-bottom' ).fadeToggle();
	} );

	block_merch_carousel();
	block_partners_carousel();
	block_events();
	block_events_carousel();
	block_faqs();
	block_photo_galleries();
	block_ads();
	block_events_filter();

	$( '.read-more' ).readmore( {
		speed: 120,
		collapsedHeight: 250,
		moreLink: '<a href="#">Read More</a>',
		lessLink: '<a href="#">Read Less</a>'
	} );
} );
